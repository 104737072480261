import ProfileListShimmer from "@components/Shared/Shimmer/ProfileListShimmer";
import UserProfile from "@components/Shared/UserProfile";
import { ArrowLeftIcon, HeartIcon } from "@heroicons/react/24/outline";
import { ProfileLinkSource } from "@hey/data/tracking";
import {
  LimitType,
  type Profile,
  type WhoReactedPublicationRequest,
  useWhoReactedPublicationQuery
} from "@hey/lens";
import { Card, EmptyState, ErrorMessage, H5 } from "@hey/ui";
import Link from "next/link";
import type { FC } from "react";
import { Virtuoso } from "react-virtuoso";
import { useProfileStore } from "src/store/persisted/useProfileStore";

interface LikesProps {
  publicationId: string;
}

const Likes: FC<LikesProps> = ({ publicationId }) => {
  const { currentProfile } = useProfileStore();

  const request: WhoReactedPublicationRequest = {
    for: publicationId,
    limit: LimitType.TwentyFive
  };

  const { data, error, fetchMore, loading } = useWhoReactedPublicationQuery({
    skip: !publicationId,
    variables: { request }
  });

  const profiles = data?.whoReactedPublication?.items;
  const pageInfo = data?.whoReactedPublication?.pageInfo;
  const hasMore = pageInfo?.next;

  const onEndReached = async () => {
    if (hasMore) {
      await fetchMore({
        variables: { request: { ...request, cursor: pageInfo?.next } }
      });
    }
  };

  if (loading) {
    return <ProfileListShimmer />;
  }

  if (profiles?.length === 0) {
    return (
      <div className="p-5">
        <EmptyState
          icon={<HeartIcon className="size-8" />}
          message="No likes."
        />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorMessage
        className="m-5"
        error={error}
        title="Failed to load likes"
      />
    );
  }

  return (
    <Card>
      <div className="flex items-center space-x-3 p-5">
        <Link href={`/posts/${publicationId}`}>
          <ArrowLeftIcon className="size-5" />
        </Link>
        <H5>Liked by</H5>
      </div>
      <div className="divider" />
      <Virtuoso
        className="virtual-divider-list-window"
        computeItemKey={(index, like) => `${like.profile.id}-${index}`}
        data={profiles}
        endReached={onEndReached}
        itemContent={(_, like) => (
          <div className="p-5">
            <UserProfile
              hideFollowButton={currentProfile?.id === like.profile.id}
              hideUnfollowButton={currentProfile?.id === like.profile.id}
              profile={like.profile as Profile}
              showBio
              showUserPreview={false}
              source={ProfileLinkSource.Likes}
            />
          </div>
        )}
        useWindowScroll
      />
    </Card>
  );
};

export default Likes;
